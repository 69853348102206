import { toRefs } from '@vueuse/core'
import { initializeApp, FirebaseApp } from 'firebase/app'
import {
  getFirestore,
  Firestore,
  Unsubscribe,
  onSnapshot,
  doc
} from 'firebase/firestore'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

export const useFirebaseState = () =>
  useState<{ app: FirebaseApp | undefined; firestore: Firestore | undefined }>(
    'firebaseApp',
    () => ({
      app: undefined,
      firestore: undefined
    })
  )

export const useFirebase = () => {
  onMounted(() => {
    initialize()
  })

  const firebase = toRefs(useFirebaseState())

  const {
    public: {
      FIREBASE_API_KEY,
      FIREBASE_AUTH_DOMAIN,
      FIREBASE_PROJECT_ID,
      FIREBASE_STORAGE_BUCKET,
      FIREBASE_MESSAGING_SENDER_ID,
      FIREBASE_APP_ID,
      FIREBASE_MEASUREMENT_ID
    }
  } = useRuntimeConfig()

  const { axios } = useApi()

  const getFirebaseToken = async (): Promise<string> => {
    const response = await axios.get('auth/firebase-token')
    return response.data.token
  }

  const initialize = async () => {
    if (firebase.app.value) {
      return
    }

    const app = initializeApp({
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
      appId: FIREBASE_APP_ID,
      measurementId: FIREBASE_MEASUREMENT_ID
    })

    const token = await getFirebaseToken()

    try {
      await signInWithCustomToken(getAuth(app), token)
      firebase.app.value = app
    } catch (error) {
      localStorage.removeItem('firebase_token')
      initialize()
    }
  }

  return firebase
}

export const useFireStore = () => {
  const firebase = useFirebase()

  const initialize = () => {
    if (!firebase.app.value || firebase.firestore.value) {
      return
    }

    firebase.firestore.value = getFirestore(firebase.app.value)
  }

  watchEffect(() => {
    initialize()
  })

  return firebase
}

export const useRealtimeSync = () => {
  onBeforeUnmount(() => {
    snapshot.value?.()
  })

  const snapshot = ref<Unsubscribe>()
  const { firestore } = useFireStore()
  const {
    authQuery: { auth },
    workspaceQuery: { data: workspace }
  } = useWorkspace()

  const { refetchQuery } = useApi()

  const initialize = () => {
    if (
      !firestore.value ||
      !workspace.value ||
      !auth.value.user ||
      snapshot.value
    ) {
      return
    }

    snapshot.value = onSnapshot(
      doc(firestore.value, 'sync', workspace.value.id),
      (doc) => {
        const data = doc.data()
        if (!data) {
          return
        }
        const { entity, userId } = data as { entity: Entity; userId: string }
        if (userId === auth.value.user?.id) {
          return
        }
        refetchQuery([entity])
      }
    )
  }

  watchEffect(() => {
    initialize()
  })
}
